<template>
    <div>
        <v-container fluid class="pa-6 ">
            <DatatableV2 :url="apiUrl" @openDrawerObject="openDrawerObject" />
        </v-container>
    </div>
</template>

<script>
import GenericDataService from '@/services/GenericDataService'
import DatatableV2 from '@/components/DatatableV2'

export default {
    name: "ExchangeList",
    components: {
        DatatableV2
    },
    data() {
        return {
            apiUrl: "/exchange/list",
        };
    },
    methods: {
        openDrawerObject(object) {
            GenericDataService.getData('/contact/' + object.contact_id.id + '/get').then((response) => {
                this.$store.dispatch("contact/SET_CONTACT_DATA", response.data.data);
                this.$store.dispatch("exchange/SET_OPEN_EXCHANGE_DRAWER", { value: true, canal: object.canal.name === 'MMS' ? 'SMS' : object.canal.name, exchange: object });
            }); 
        }
    }
};
</script>
